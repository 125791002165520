import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/uiLibrary/Typography';
import Modal from 'components/uiLibrary/Modal';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';

import { useReviewDetails } from 'utils/reviews';
import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './ReviewModal.module.scss';

const ReviewModal = ({ content, onCloseModal, trackingData }) => {
  const { t } = useTranslation();
  const track = useTracking();

  const details = useReviewDetails(content);

  const handleCloseModal = () => {
    onCloseModal();
    track.click({
      ...trackingData,
      component: COMPONENTS.REVIEW_MODAL,
      subComponent: SUB_COMPONENTS.CLOSE_CTA,
    });
  };

  return (
    <Modal isOpen onClose={handleCloseModal} title={t(`${TP}.FULL_REVIEW`)} allowMobileDrawer>
      <div className={classes.reviewModal}>
        <div className={classes.mainContent}>
          <SpriteIcon icon="quotes_open" className={classes.openingQuotes} />
          {details?.title && (
            <Typography type="medium" size="14">
              {details?.title}
            </Typography>
          )}
          {details?.content && (
            <Typography className={classes.mainContent_description} size="12" color="secondary" variant="p">
              {details?.content}
            </Typography>
          )}
          <SpriteIcon icon="quotes_close" className={classes.closingQuotes} />
        </div>
        <div className={classes.metaInfo}>
          {details?.reviewDate && (
            <Typography className={classes.metaInfo_date} size="12" color="secondary">
              {details?.reviewDate}
            </Typography>
          )}
          {details?.link && (
            <LinkButton
              isLink
              variant="text"
              href={details?.link}
              styles={{ root: classes.metaInfo_website }}
              external
              trackingData={{
                ...trackingData,
                component: COMPONENTS.REVIEW_MODAL,
                subComponent: SUB_COMPONENTS.REVIEW_LINK,
                meta: { ...trackingData?.meta, link: details?.link },
              }}
            >
              {details?.hostname}
            </LinkButton>
          )}
          {details?.author && (
            <Typography size="12" className={classes.metaInfo_author}>
              {details?.author}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.reviewModal__footer}>
        <LinkButton styles={{ root: classes.footerButton }} onClick={handleCloseModal} skipTracking>
          {t(`${TP}.m_BACK_TO_REVIEWS`)}
        </LinkButton>
      </div>
    </Modal>
  );
};

ReviewModal.propTypes = {
  content: PropTypes.object,
  onCloseModal: PropTypes.func,
};

export default React.memo(ReviewModal);
